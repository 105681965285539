import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const PricePage = () => {
  // Code for Courses page view for Google
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.gtag != null) {
        window.gtag("event", "conversion", {
          send_to: ["AW-11031846865/22fQCKmIn4QYENG_sowp"],
        })
      }
    }
  })

  return (
    <Layout activeMenu="courses">
      <SEO title="Courses" />

      {/* <div className="banner_w3lspvt-2">
			<ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" className="font-weight-bold">Home</Link>
        </li>
				<li className="breadcrumb-item" aria-current="page">Pricing</li>
			</ol>
    </div> */}

      {/* <div className="price-sec py-3">
      <div className="container py-xl-5 py-lg-3">
        <div className="inner_sec_info_w3_info mt-3">
          <div className="row price-grid-main">
            <div className="col-lg-4 col-sm-6 price-info">
              <div className="prices p-4">
                <div className="prices-top">
                  <div className="rounded-circle">
                    <h3 className="text-center text-wh">$0.00</h3>
                    <p className="text-center text-wh">per class</p>
                  </div>
                </div>
                <div className="prices-bottom text-center mt-4">
                  <div className="prices-h border-bottom ">
                    <h4>FREE Trial Class</h4>
                    <img
                      src="../images/free.ico"
                      alt=""
                      className="img-fluid m-3"
                      style={{ width: "111px" }}
                    />
                  </div>
                  <div className="mt-4">
                    <h5>Perks:</h5>
                    <p>
                      <span class="fa solid fa-check tick-icon"></span>
                      FREE of cost.
                    </p>
                    <p>
                      <span class="fa solid fa-check tick-icon"></span>
                      30 mins duration.
                    </p>
                    <p>
                      <span class="fa solid fa-check tick-icon"></span>
                      Help in software setup.
                    </p>
                    {/* <h5 className="mt-4">Book Now</h5> 
                    <a
                      href="/contact"
                      rel="noopener noreferrer"
                      className="btn button-orange mt-md-4 mt-4 read-more"
                    >
                      Book a FREE trial class
                    </a>
                    <p style={{ visibility: "hidden" }}>
                      Students will design and publish an Obby game on
                      Roblox.com.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6  price-info price-mkres-2">
              <div className="prices p-4 active">
                <div className="prices-top">
                  <div className="rounded-circle">
                    <h3 className="text-center text-wh">$30</h3>
                    <p className="text-center text-wh">per class</p>
                  </div>
                </div>
                <div className="prices-bottom text-center  mt-3">
                  <div className="prices-h border-bottom ">
                    <h4>Group Class</h4>
                    <img
                      src="../images/group.jpg"
                      alt=""
                      className="img-fluid  m-2"
                    />
                  </div>
                  <div className="mt-4">
                    <h5>Perks:</h5>
                    <p>
                      <p>
                        <span class="fa solid fa-check tick-icon"></span>
                        1.5 hours duration.
                      </p>
                      <p>
                        <span class="fa solid fa-check tick-icon"></span>
                        3-4 students in a class.
                      </p>
                      <p>
                        <span class="fa solid fa-check tick-icon"></span>
                        Once a week.
                      </p>
                    </p>
                    <h5 className="mt-4">Package</h5>
                    <p>10 classes - $300CAD</p>
                    <p>
                      GET <span style={{ color: "red" }}>$50 </span>off as
                      Siblings Discount when you register for 2 kids.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6  price-info price-mkres">
              <div className="prices p-4">
                <div className="prices-top">
                  <div className="rounded-circle">
                    <h3 className="text-center text-wh">$40</h3>
                    <p className="text-center text-wh">per class</p>
                  </div>
                </div>
                <div className="prices-bottom text-center mt-3">
                  <div className="prices-h border-bottom">
                    <h4>Private Class</h4>
                    <img
                      src="../images/private-class.png"
                      alt=""
                      className="img-fluid  m-2"
                      style={{ width: "134px" }}
                    />
                  </div>
                  <div className="mt-4">
                    <h5>Perks:</h5>
                    <p>
                      <span class="fa solid fa-check tick-icon"></span>
                      Set your own availibility.
                    </p>
                    <p>
                      <span class="fa solid fa-check tick-icon"></span>
                      Learn at your own pace.
                    </p>
                    <p>
                      <span class="fa solid fa-check tick-icon"></span>1 hour
                      duration.
                    </p>
                    <h5 className="mt-4">Package</h5>
                    <p>10 classes - $400CAD</p>
                    <p>
                      GET <span style={{ color: "red" }}>$50 </span>off as
                      Siblings Discount when you register for 2 kids.
                    </p>
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      <div className="what bg-li py-5" id="what">
        <div className="container py-xl-5 py-lg-3">
          <h3
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="1500"
            data-sal-easing="ease-out-back"
            className="tittle text-center font-weight-bold"
          >
            Our <span className="title-span">Courses</span>
          </h3>
          <div className="row about-bottom-w3l text-center mt-4">
            <div className="col-lg-4 about-grid">
              <div className="about-grid-main">
                <img
                  src="/images/roblox-logo.png"
                  alt=""
                  className="img-fluid w-50 m-1"
                />
                <div className="card-body">
                  <h4 className="my-4">Game Development</h4>
                  <div className="mb-1 duration">
                    <span>Age Group:</span> 7-16
                  </div>
                  <p>
                    It is an introductory level course that will help kids to
                    create and publish their first game on Roblox.com
                  </p>
                </div>
                <div className="mt-2 duration">
                  {/* <span>Total Classes:</span> 40 */}
                  <br />
                  <span>Duration:</span> 8 months
                </div>
                <div className="center">
                  <a target="_blank" href="">
                    <button className="btn btn-cont-w3 btn-block mt-4">
                      Enroll now
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 about-grid my-lg-0 my-5">
              <div className="about-grid-main">
                <img
                  src="/images/pythonlogo.png"
                  alt=""
                  className="img-fluid w-50 m-2"
                />
                <div className="card-body">
                  <div>
                    <h4 className="my-4">Python Programming</h4>
                  </div>
                  <div className="mb-1 duration">
                    <span>Age Group:</span> 10-16
                  </div>
                  <p>
                    Python is a powerful, expressive programming language that’s
                    easy to learn and fun to use!
                    {/* Staring from the fundamentals of python programming language (level 1) to adavnce level (level 2)  */}
                  </p>
                </div>
                <div className="mt-2 duration">
                  {/* <span>Total Classes:</span> 48 */}
                  <br />
                  <span>Duration:</span> 10 months
                </div>{" "}
                <div className="center">
                  <a target="_blank" href="">
                    <button className="btn btn-cont-w3 btn-block mt-4">
                      Enroll now
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 about-grid">
              <div className="about-grid-main">
                <img
                  src="/images/web-dev.png"
                  alt=""
                  className="img-fluid w-50 m-2"
                />
                <div className="card-body">
                  <div>
                    <h4 className="my-4">
                      Web <br /> Development
                    </h4>
                  </div>
                  <div className="mb-1 duration">
                    <span>Age Group:</span> 10-16
                  </div>
                  <p>
                    Introduction to HTML, CSS, and JavaScript to help the kids
                    develop and host their own site
                  </p>
                </div>
                <div className="mt-2 duration">
                  {/* <span>Total Classes:</span> 40 */}
                  <br />
                  <span>Duration:</span> 12 months
                </div>
                <div className="center">
                  <a target="_blank" href="">
                    <button className="btn btn-cont-w3 btn-block mt-4">
                      Enroll now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd row */}
          <div className="row about-bottom-w3l text-center mt-4">
            <div className="col-lg-4 about-grid">
              <div className="about-grid-main">
                <img
                  src="/images/scratch.png"
                  alt=""
                  className="img-fluid w-50 m-1"
                />
                <div className="card-body">
                  <h4 className="my-4">Scratch Coding</h4>
                  <div className="mb-1 duration">
                    <span>Age Group:</span> 5-7
                  </div>
                  <p>
                    This course introduces young minds to coding through the
                    user-friendly Scratch platform, sparking creativity and
                    innovation.
                  </p>
                </div>
                <div className="mt-2 duration">
                  {/* <span>Total Classes:</span> 40 */}
                  <br />
                  <span>Duration:</span> 8 months
                </div>
                <div className="center">
                  <a target="_blank" href="">
                    <button className="btn btn-cont-w3 btn-block mt-4">
                      Enroll now
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 about-grid my-lg-0 my-5">
              <div className="about-grid-main">
                <img
                  src="/images/Robot.png"
                  alt=""
                  className="img-fluid w-50 m-2"
                />
                <div className="card-body">
                  <div>
                    <h4 className="my-4">Introduction to A.I (Coming Soon!)</h4>
                  </div>
                  <div className="mb-1 duration">
                    {/* <span>Age Group:</span> 10-16 */}
                  </div>
                  <p>
                    This course introduces young minds to the world of
                    Artificial Intelligence, exploring how it works through
                    hands-on projects and interactive activities.
                  </p>
                </div>
                <div className="mt-2 duration">
                  {/* <span>Total Classes:</span> 48 */}
                  <br />
                  {/* <span>Duration:</span> 10 months */}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-4">
        <div id="python" className="mb-3 mt-5">
          <h3 className="tittle text-center font-weight-bold">
            Scratch <span className="title-span">Coding</span>{" "}
            <span className="sub-heading">( Age: 5-7 )</span>
          </h3>
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <h5>Coure Description</h5>
            <p className="text-justify">
              This course is designed to introduce young minds to the wonders of
              coding through the user-friendly Scratch platform, igniting a
              passion for creativity and innovation. It empowers kids to explore
              endless possibilities using colourful blocks that enable them to
              create animated characters, compose music, and games.
            </p>
            <a target="_blank" href="">
              <button className="btn btn-cont-w3 btn-block mt-4">
                Enroll now
              </button>
            </a>
            {/* <h5 className="mt-2">Course Timeline:</h5>
            <div className="container-fluid">
              <div id="timeline-container">
                <div class="inner-container">
                  <ul class="timeline">
                    <li class="timeline-item" data-date="1 Month">
                      Intro to Python
                    </li>
                    <li class="timeline-item" data-date="1.5 Months">
                      Beginners Level
                    </li>
                    <li class="timeline-item" data-date="1.5 Months">
                      Intermediate Level
                    </li>
                    <li class="timeline-item" data-date="2 Months">
                      Advance Level
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <h5 className="mt-2">Course Includes:</h5>
          <p>
            Python installation & IDE setup, variables, data types, conditional
            statements, operators, loops, and functions.
          </p> */}
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="iframe-container">
              <iframe
                src="https://drive.google.com/file/d/13yuLbMH-5OgdGozeoWgY6V1xTq1FKmWT/preview"
                // width="560"
                // height="285"
                // style="border:none;overflow:hidden"
                className="img-fluid w-100 m-2 responsive-iframe"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
              ></iframe>
            </div>
            <div className="mt-2 pb-2">
              <h5>Outcome:</h5>
              <p className="text-justify">
                Throughout the course, students will progressively develop their
                creativity, problem-solving skills, and understanding of
                programming concepts using Scratch. The modules are designed to
                engage beginners in a hands-on, interactive learning experience.
              </p>
            </div>
          </div>
        </div>

        <div id="roblox" className="mb-2 mt-5">
          <h3 className="tittle text-center font-weight-bold ">
            Roblox <span className="title-span">Game Dev</span>{" "}
            <span className="sub-heading">( Age: 7-16 )</span>
          </h3>
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="iframe-container">
              <iframe
                src="https://www.facebook.com/plugins/video.php?height=285&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F621443002935048%2F&show_text=false&width=560&t=0"
                className="img-fluid w-100 m-2 responsive-iframe"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
              ></iframe>
            </div>
            <div className="mt-2 pb-2">
              <h5>Outcome:</h5>
              <p className="text-justify">
                In the Roblox Game Development course, students will master the
                basics of Roblox Studio, including variables, parts, and their
                properties. They’ll build interactive logic using conditional
                statements, loops, and functions, culminating in publishing
                their custom multi-level Obby game on Roblox's website.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <h5>Course Description</h5>
            <p className="text-justify">
              In the engaging Roblox Game Development course, young participants
              will acquire comprehensive proficiency in leveraging Roblox
              Studio, a powerful and user-friendly game-building engine.
              Throughout the course, they will embark on an exciting journey,
              culminating in the creation and publication of a captivating Obby
              game. This will provide them with an immersive educational
              experience in the world of game development,
            </p>
            <a target="_blank" href="">
              <button className="btn btn-cont-w3 btn-block mt-4">
                Enroll now
              </button>
            </a>
            {/*<h5 className="mt-2">Course Timeline:</h5>
             <div className="container-fluid">
              <div id="timeline-container">
                <div class="inner-container">
                  <ul class="timeline">
                    <li class="timeline-item" data-date="1 Month">
                      Intro to Roblox & Modeling
                    </li>
                    <li class="timeline-item" data-date="2 Months">
                      Scripting in Lua
                    </li>
                    <li class="timeline-item" data-date="2 Months">
                      GUI & Developer Products
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <p>
            Intoduction to Roblox Studio, Game desiging, variables, functions,
            loops, events, data-storing, tools creation.
          </p> */}
          </div>
        </div>
        <div id="python" className="mb-3 mt-5">
          <h3 className="tittle text-center font-weight-bold">
            Python <span className="title-span">Programming</span>{" "}
            <span className="sub-heading">( Age: 10-16 )</span>
          </h3>
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <h5>Coure Description</h5>
            <p className="text-justify">
              Discover the exciting world of coding with our "Introduction to
              Python Programming" course. This beginner-friendly journey will
              equip young learners with essential coding skills as they explore
              Python's versatile features. From creating captivating turtle
              graphics to mastering variables, loops, functions, and data
              structures, students will embark on an engaging adventure that
              culminates in hands-on project challenges. This course fosters
              problem-solving, creativity, and logical thinking, setting the
              stage for a strong foundation in programming.
            </p>
            <a target="_blank" href="">
              <button className="btn btn-cont-w3 btn-block mt-4">
                Enroll now
              </button>
            </a>
            {/* <h5 className="mt-2">Course Timeline:</h5>
            <div className="container-fluid">
              <div id="timeline-container">
                <div class="inner-container">
                  <ul class="timeline">
                    <li class="timeline-item" data-date="1 Month">
                      Intro to Python
                    </li>
                    <li class="timeline-item" data-date="1.5 Months">
                      Beginners Level
                    </li>
                    <li class="timeline-item" data-date="1.5 Months">
                      Intermediate Level
                    </li>
                    <li class="timeline-item" data-date="2 Months">
                      Advance Level
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <h5 className="mt-2">Course Includes:</h5>
          <p>
            Python installation & IDE setup, variables, data types, conditional
            statements, operators, loops, and functions.
          </p> */}
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="iframe-container">
              <iframe
                src="https://www.facebook.com/plugins/video.php?height=350&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F463207542058344%2F&show_text=false&width=560&t=0"
                // width="560"
                // height="285"
                // style="border:none;overflow:hidden"
                className="img-fluid w-100 m-2 responsive-iframe"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
              ></iframe>
            </div>
            <div className="mt-2 pb-2">
              <h5>Outcome:</h5>
              <p className="text-justify">
                By the end of this course, students will have developed engaging
                projects like a quiz game, calculator game, to-do app, hangman
                game, and a random quote generator. They will master core Python
                programming concepts through these hands-on activities and gain
                confidence in building interactive applications. This foundation
                will prepare them to tackle more advanced programming challenges
                in the future.
              </p>
            </div>
          </div>
        </div>
        <div id="web" className="mb-2 mt-5">
          <h3 className="tittle text-center font-weight-bold mb-3 mt-4">
            Web <span className="title-span">Development</span>{" "}
            <span className="sub-heading">( Age: 10-16 )</span>
          </h3>
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="iframe-container">
              <iframe
                src="https://www.facebook.com/plugins/video.php?height=262&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F1016156739057090%2F&show_text=false&width=560&t=0"
                // width="560"
                // height="285"
                // style="border:none;overflow:hidden"
                className="img-fluid w-100 m-2 responsive-iframe"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
              ></iframe>
            </div>
            <div className="pb-2">
              <h5>Outcome:</h5>
              <p className="text-justify">
                By the end of this course, students will have built a web
                portfolio, a custom calculator, a to-do app, and a personalized
                website on a topic they love. They will gain a strong foundation
                in HTML, CSS, and JavaScript, enabling them to create
                responsive, interactive web applications. Equipped with these
                skills, they will be ready to explore advanced web development
                topics and technologies.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <h5>Course Description</h5>
            <p className="text-justify">
              Are you ready to unlock the world of web development and bring
              your creative ideas to life? This course is designed to guide
              students through the exciting journey of building websites from
              scratch. Whether you’re a beginner or have some coding experience,
              you’ll learn how to create stunning, responsive, and interactive
              websites using HTML, CSS, and JavaScript.
            </p>
            <a target="_blank" href="">
              <button className="btn btn-cont-w3 btn-block mt-4">
                Enroll now
              </button>
            </a>

            {/* <h5 className="mt-2">Course Timeline:</h5>
            <div className="container-fluid">
              <div id="timeline-container">
                <div class="inner-container">
                  <ul class="timeline">
                    <li class="timeline-item" data-date="1.5 Months">
                      Html
                    </li>
                    <li class="timeline-item" data-date="1.5 Months">
                      CSS & Bootstrap
                    </li>
                    <li class="timeline-item" data-date="2 Months">
                      JavaScript
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}

            {/* <p>
            HTML5 tags, CSS3, Bootstrap library, JavaScript: variables, data
            types, conditional statements, operators, loops, and functions.
          </p> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricePage
